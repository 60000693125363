<template>
    <div>
        <div class="flex">
            <div :class="wrapperClass">

                <h1 class="text-2xl font-bold mb-5">Credit Application</h1>

                <div :class="cardClass">
                    <component :is="step" :can-check="canCheck" @success="next" @repeat="repeat"></component>
                </div>

            </div>
        </div>

        <modal className="w-full md:w-2/5 xl:w-3/10 text-center pt-24" ref="successModal">

            <img :src="checkmark" class="mx-auto mb-8" height="90" alt="Checkmark">

            <div class="text-lg font-bold mb-4">
                Success!
            </div>
            <div class="text-sm mb-10">
                Your application has been submitted and will be reviewed
            </div>

            <router-link :to="{ name: 'dashboard' }" class="btn btn-blue btn-md mb-10">
                Go To Dashboard
            </router-link>

        </modal>

        <modal className="w-full md:w-2/5 xl:w-3/10 text-center pt-24" ref="repeatModal" @close="leave">

            <img :src="exclamation" class="mx-auto mb-8" height="90" alt="Exclamation">

            <div class="text-lg font-bold mb-4">
                You've already Applied.
            </div>
            <div class="text-sm mb-10">
                Your application is still being reviewed. We'll get back to your shortly.
            </div>

            <router-link :to="{ name: 'dashboard' }" class="btn btn-blue btn-md mb-10">
                Go To Dashboard
            </router-link>

        </modal>

    </div>
</template>

<script>
    export default {
        components: {
            backendCheck: require('./BackendCheck.vue').default,
            documents: require('./Documents.vue').default,
            application: require('./Application.vue').default,
        },
        data() {
            return {
                canCheck: false,
                checkmark: require('@/assets/checkmark-base.svg'),
                exclamation: require('@/assets/exclamation.svg'),
                step: 'backendCheck',
                // step: 'application',
            }
        },
        computed: {
            cardClass() {
                if (this.step === 'backendCheck') {
                    return 'card bg-blue-100 pt-16 pb-10 lg:px-10 xl:px-16';
                }

                return 'card border border-blue-200 pt-16 pb-10 lg:px-10 xl:px-16';
            },
            wrapperClass() {
                if (this.step === 'backendCheck') {
                    return 'w-full sm:w-2/3 md:w-3/5 lg:w-4/5 xl:w-1/2 mx-auto';
                }

                return 'w-full mx-auto';
            },
        },
        mounted() {
            this.canCheck = true;
        },
        methods: {
            next() {
                switch (this.step) {
                    case 'application':
                        this.success();
                        break;
                    case 'documents':
                        this.step = 'application';
                        break;
                    case 'backendCheck':
                    default:
                        this.step = 'documents';
                        break;
                }
            },
            leave() {
                this.$router.push({ name: 'dashboard' });
            },
            repeat() {
                this.$refs.repeatModal.open();
            },
            success() {
                this.$refs.successModal.open();
            }
        }
    }
</script>