<template>
    <form @submit.prevent="submit">

        <template v-if="getFormError(form)">
            <div class="alert alert-red-soft mb-10">
                <div class="alert-icon">!</div>
                <div class="">{{ getFormError(form) }}</div>
            </div>
        </template>

        <div class="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8">

            <div class="col-span-1">
                <form-group
                    left-icon="business-outline"
                    name="business_name"
                    :form="form"
                    v-model="form.data.business_name.value"
                >
                    Input business legal name
                </form-group>
            </div>

            <div class="col-span-1">
                <form-group
                    type="select"
                    :options="form.ages"
                    left-icon="medal-outline"
                    name="no_of_years"
                    :form="form"
                    v-model="form.data.no_of_years.value"
                >
                    Number of years in business
                </form-group>
            </div>

            <div class="col-span-1">
                <form-group
                    type="money"
                    left-icon="cash-outline"
                    name="annual_revenue"
                    :form="form"
                    v-model="form.data.annual_revenue.value"
                >
                    Annual business revenue
                </form-group>
            </div>

            <div class="col-span-1">
                <form-group
                    type="money"
                    left-icon="cash-outline"
                    name="est_monthly_expenses"
                    :form="form"
                    v-model="form.data.est_monthly_expenses.value"
                >
                    Estimated monthly expenses
                </form-group>
            </div>

            <div class="col-span-1">
                <form-group
                    type="money"
                    left-icon="bar-chart-outline"
                    name="annual_gross_revenue"
                    :form="form"
                    v-model="form.data.annual_gross_revenue.value"
                >
                    Total annual gross profit
                </form-group>
            </div>

            <div class="col-span-1">
                <form-group
                    left-icon="finger-print-outline"
                    name="directors_bvn"
                    :form="form"
                    v-model="form.data.directors_bvn.value"
                >
                    BVN of director 1
                </form-group>
            </div>

            <div class="col-span-1">
                <form-group
                    left-icon="finger-print-outline"
                    name="second_bvn"
                    :form="form"
                    v-model="form.data.second_bvn.value"
                >
                    BVN of director 2 (optional)
                </form-group>
            </div>

            <div class="col-span-1">
                <form-group
                    left-icon="finger-print-outline"
                    name="third_bvn"
                    :form="form"
                    v-model="form.data.third_bvn.value"
                >
                    BVN of director 3 (optional)
                </form-group>
            </div>

        </div>


        <div class="text-right">
            <button
                type="submit"
                class="btn btn-blue"
                :disabled="form.loading"
            >
                <span v-if="form.loading">Submitting</span>
                <span v-else>Submit Application</span>
            </button>
        </div>

    </form>

</template>

<script>
    export default {
        data() {
            return {
                form: this.$options.basicForm([
                    'business_name',
                    'no_of_years',
                    'annual_revenue',
                    'est_monthly_expenses',
                    'annual_gross_revenue',
                    'directors_bvn',
                    {name: 'second_bvn', rules: 'nullable'},
                    {name: 'third_bvn', rules: 'nullable'},
                ], {
                    ages: [
                        '< 1',
                        '1-3',
                        '3-5',
                        '5 and above',
                    ]
                }),
            }
        },
        methods: {
            async submit() {
                if (!this.validateForm(this.form)) {
                    return false;
                }

                this.form.loading = true;

                await this.sendRequest('corporate.creditApplication.submitApplication', {
                    data: {
                        ...this.getFormData(),
                        companyId: this.user.company_id
                    },
                    success: (response) => {
                        this.$emit('success', response);
                    },
                    error: error => {
                        this.form.error = error;
                        console.log([error]);
                    }
                });

                this.form.loading = false;
            }
        }
    }
</script>