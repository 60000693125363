<template>
    <form @submit.prevent="submit">

        <template v-if="getFormError(form)">
            <div class="alert alert-red-soft mb-10">
                <div class="alert-icon">
                    !
                </div>
                <div class="">{{ getFormError(form) }}</div>
            </div>
        </template>

        <div class="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8">
            
            <div class="col-span-1">
                <label class="block text-sm mb-4">Two years bank statement</label>
                <dropzone accept=".pdf" v-model="form.data.bank_statement.value"></dropzone>
                <div class="text-sm -mt-8 mb-10 text-red-500" v-if="getFirstError('bank_statement')">{{ getFirstError('bank_statement') }}</div>
            </div>

            <div class="col-span-1">
                <label class="block text-sm mb-4">CAC form Co2 - Share Allotment</label>
                <dropzone accept=".pdf" v-model="form.data.share_allotment_doc.value"></dropzone>
                <div class="text-sm -mt-8 mb-10 text-red-500" v-if="getFirstError('share_allotment_doc')">{{ getFirstError('share_allotment_doc') }}</div>
            </div>

            <div class="col-span-1">
                <label class="block text-sm mb-4">CAC form Co7 - Particulars of directors</label>
                <dropzone accept=".pdf" v-model="form.data.director_particulars_doc.value"></dropzone>
                <div class="text-sm -mt-8 mb-10 text-red-500" v-if="getFirstError('director_particulars_doc')">{{ getFirstError('director_particulars_doc') }}</div>
            </div>

            <div class="col-span-1">
                <label class="block text-sm mb-4">Board resolution (To take up CredPal Credit)</label>
                <dropzone accept=".pdf" v-model="form.data.resolution_docs.value"></dropzone>
                <div class="text-sm -mt-8 mb-10 text-red-500" v-if="getFirstError('resolution_docs')">{{ getFirstError('resolution_docs') }}</div>
            </div>

            <div class="col-span-1">
                <label class="block text-sm mb-4">National ID of director 1</label>
                <dropzone accept=".png, .jpg" v-model="form.data.director_national_id.value"></dropzone>
                <div class="text-sm -mt-8 mb-10 text-red-500" v-if="getFirstError('director_national_id')">{{ getFirstError('director_national_id') }}</div>
            </div>

        </div>



        <div class="text-right">
            <button
                type="submit"
                class="btn btn-blue"
                :disabled="form.loading"
            >
                <span v-if="form.loading">Uploading</span>
                <span v-else>Upload Documents</span>
            </button>
        </div>

    </form>

</template>

<script>
    export default {
        data() {
            return {
                form: this.$options.basicForm([
                    { name: 'bank_statement', value: null },
                    { name: 'share_allotment_doc', value: null },
                    { name: 'director_particulars_doc', value: null },
                    { name: 'director_national_id', value: null },
                    { name: 'resolution_docs', value: null },
                ]),
            }
        },
        methods: {
            async submit() {
                if (!this.validateForm(this.form)) {
                    return false;
                }

                const data = new FormData;

                for (let name in this.form.data) {
                    data.append(name, this.form.data[name].value);
                }

                data.append('company_id', this.user.company_id);

                this.form.loading = true;

                await this.sendRequest('corporate.creditApplication.documents', {
                    data,
                    success: (response) => {
                        this.$emit('success', response);
                    },
                    error: error => {
                        this.form.error = error;
                        console.log([error]);
                    }
                });

                this.form.loading = false;
            }
        }
    }
</script>