<template>
    <div class="">
        
        <div class="text-xl font-bold mb-10">Validating Account</div>

        <template v-if="getFormError(backendCheck)">
            <div class="alert alert-red-soft mb-10">
                <div class="alert-icon">
                    !
                </div>
                <div class="">{{ getFormError(backendCheck) }}</div>
            </div>
        </template>

        <div class="text-sm mb-3">{{ percentage }}</div>
        <div class="progress-bar progress-bar-blue mb-10">
            <div class="progress" :style="{width: percentage}">
            </div>
        </div>

        <template v-if="backendCheck.error">
            <button type="button" class="btn btn-blue" @click.prevent="beginCheck">Try Again</button>
        </template>
        <template v-else>
            <div class="mb-10">This usually doesn't take long</div>
        </template>

    </div>
</template>

<script>
    export default {
        props: {
            canCheck: {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {
                backendCheck: this.$options.resource(null, { interval: null, progress: 0, speed: 100, maxSpeed: 100 }),
            }
        },
        computed: {
            percentage() {
                return this.backendCheck.success ? '100%' : `${this.backendCheck.progress}%`
            }
        },
        watch: {
            canCheck(data) {
                if (data === true) {
                    this.beginCheck();
                }
            }
        },
        mounted() {
            if (this.canCheck) {
                this.beginCheck();
            }
        },
        methods: {
            async beginCheck() {

                this.backendCheck.error = false;

                this.startProgress();

                await new Promise(resolve => setTimeout(resolve, 100));

                await this.sendRequest('corporate.creditApplication.backendCheck', {
                    data: {
                        companyId: this.user.company_id
                    },
                    success: (response) => {
                        if (response.data.redirectTo) {
                            this.$emit('success', response);
                        }else {
                            this.$emit('repeat', response);
                        }
                    },
                    error: error => {
                        if (error.response?.data?.redirectTo) {
                            this.$emit('success', error.response);
                        } else {
                            this.backendCheck.error = error;
                        }
                        console.log(error);
                    }
                });

                this.completeProgress();
            },
            completeProgress() {
                this.backendCheck.progress = 100;
                this.stopProgress();
            },
            startProgress() {
                this.backendCheck.progress = 0;
                this.backendCheck.speed = this.backendCheck.maxSpeed;

                const recurse = () => {
                    this.backendCheck.interval = setTimeout(() => {
                        if (this.backendCheck.progress >= 71) {
                            return this.stopProgress();
                        }

                        this.backendCheck.progress += 1;

                        this.backendCheck.speed += (this.backendCheck.speed * this.backendCheck.progress / 100 / 5)

                        recurse();
                    }, this.backendCheck.speed);
                }

                recurse();
            },
            stopProgress() {
                clearTimeout(this.backendCheck.interval)
            }
        }
    }
</script>